<template>
  <div class="introducePage">
    <div class="section-aside">
      <p>海曙斑马青年</p>
      <p>志愿者服务队</p>
    </div>
    <div class="section-main">
      <div class="main-content">
        <p>
          海曙斑马青年志愿者服务队是由望春街道社会组织服务中心培育的一支专业救援队，成立于2021年。成员来自街道辖区内社会各界爱心人士，他们擅长急救、抗台、核酸、文明等专业志愿服务，致力于无偿社会公益服务。
        </p>
        <p>
          自成立以来，服务队组织多次防疫抗台行动，无偿献血，抢险救援……能在别人需要的时候伸出援手，是每个斑马青年志愿者服务队员们的心愿。服务队定期组织技能培训，交流学习，为每一个救援做好充足的准备，积极将这份正能量传递给更多的人。
        </p>
      </div>

      <div class="main-video">
        <audio
          controls
          id="audios"
          height="50"
          autoplay="autoplay"
          src="./audio/introducePageFive_bg.mp3"
        ></audio>
      </div>

      <div class="main-picture">
        <img src="./img/introducePageFive_show_one.jpg" al t="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageFive_show_two.jpg" alt="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageFive_show_three.jpg" alt="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageFive_show_four.jpg" alt="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePageFive_show_five.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: null,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const that = this;
      //--创建页面监听，等待微信端页面加载完毕 触发音频播放
      document.addEventListener("WeixinJSBridgeReady", function () {
        that.$nextTick(() => {
          document.getElementById("audios").play();
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.introducePage {
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 378px 0 80px;
  background: url(./img/introducePage_bg.png) no-repeat top left;
  background-size: 100% auto;
  background-color: #dfeeff;
  .section-aside {
    position: absolute;
    top: 160px;
    left: 36px;
    font-size: 72px;
    color: #ffffff;
    line-height: 84px;
    text-shadow: 5px 2px #4a7196;
    p:last-child {
      padding-left: 40px;
    }
  }
  .section-main {
    box-sizing: border-box;
    padding: 38px 30px 0px;
    width: 100%;
    background: url(./img/introducePage_across.png) no-repeat top center;
    background-size: calc(100% - 20px) 80px;
    .main-content {
      // width: 100%;
      padding: 50px 30px 56px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      font-size: 28px;
      line-height: 40px;
      color: rgba(0, 0, 0, 0.85);
      // text-align-last: justify;
      text-align: justify;
      text-indent: 2em;
    }
    .main-video {
      position: relative;
      margin-top: 30px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      padding: 40px 22px 40px;
      text-align: center;
      height: 100px;
      ::v-deep video {
        width: 100%;
        height: 100%;
        object-fit: fill;
        background: #fff;
      }
    }
    .main-picture {
      position: relative;
      margin-top: 30px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      padding: 68px 22px 94px;
      img {
        width: 100%;
        // width: 646px;
        // height: 486px;
      }
      &:not(:last-child)::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -68px;
        left: 28px;
        width: 634px;
        height: 106px;
        background: url(./img/introducePage_vertical.png) no-repeat top center;
        background-size: 100% 100%;
        z-index: 1;
      }
    }
  }
}
</style>
